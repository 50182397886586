<!-- 首页内容 -->
<template>
    <div class="Dashboard">
        <div class="" style="font-size:20px;font-weight: bold; padding: 25px;">
            首页
        </div>
        <!-- 二维码 -->
        <div class="ervm-txt">
            <div class="ervm">
                <img :src="QRcode" >
            </div>
            <div class="txt">
                <div class="" style="font-size: 18px; color: #333;margin-bottom: 20px;font-weight: bold;">
                    {{name}}
                </div>
                <span>本培训机构所属考生，通过扫描上面二维码，自助提交报名资料</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                QRcode:"",//二维码
                name:"",
                info:{}
            }
        },
        created() {
            this.info=JSON.parse(localStorage.getItem("get_info"))
            console.log(this.info)
            this.name=this.info.name
            this.$request({url: '/api/examsignup/build', method: 'POST', data:{
                mechanism_id:localStorage.getItem('token')
            }}).then(res=>{
                // console.log(res)
                this.QRcode=res.data//赋值二维码图片路径
            })
        },

        methods:{
            
        }
    }
</script>

<style scoped="scoped">
    .Dashboard{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .ervm-txt{
        width:400px;
        height: 300px;
        position: absolute;
        top: 50%;
        left:50%;
        transform:translate(-50%,-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .ervm{
        width:256px;
        height:256px;
        /* border: #000000 1px solid; */
    }
    .ervm img{
        width: 100%;
        height: 100%;
    }
    .txt{
        text-align: center;
        width: 100%;
        font-size: 14px;
        height: 19px;
        line-height: 19px;
        color: #707070;
    }
</style>
